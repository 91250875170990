<template>
  <div class="w-full note-content-container">
    <div class="flex items-center justify-between">
      <p class="note-time">
        {{ $dayjs(content.create_time * 1000).format("MMMM D, YYYY") }}
      </p>
      <img src="../assets/arrow.png" class="arrow-icon" />
    </div>
    <div class="note-title">{{ content.title }}</div>
    <p class="user-content">{{ content.user_info.user_nickName || '-'}}</p>
    <div class="note-content">{{ content.summary }}</div>
  </div>
</template>
<script>
import { TagColorList } from "../utils/common";
export default {
  name: "Article",
  props: {
    content: {
      type: Object,
    },
  },
  data() {
    return {
      TagColorList,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.note-content-container {
  border-radius: 12px;
  border: 1px solid #edf2f7;
  padding: 16px;
  cursor: pointer;
  font-family: Poppins;
  height: 240px;
  position: relative;
  display: flex;
  flex-direction: column;
  .note-time {
    color: #005a64;
    font-size: 12px;
    font-weight: 400;
  }
  .arrow-icon {
    width: 16px;
    height: 16px;
    transform: rotate(-90deg);
  }
  .note-title {
    margin: 16px 0 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: #1a202c;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .user-content {
    font-size: 12px;
    text-decoration: underline;
    color: #005a64;
    margin-top: 4px;
  }
  .note-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-top: 12px;
  }
}
</style>
