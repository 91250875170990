import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=3afb09ac&scoped=true"
import script from "./Article.vue?vue&type=script&lang=js"
export * from "./Article.vue?vue&type=script&lang=js"
import style0 from "./Article.vue?vue&type=style&index=0&id=3afb09ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3afb09ac",
  null
  
)

export default component.exports